import { takeLatest, put, call } from 'redux-saga/effects'
import { USER } from '../actions/types'
import {
  getCartSuccessful,
  getCart,
  getFavouritesSuccessful,
  getFavourites
} from '../actions/user'
import {
  loading,
  loadingFinish,
  updatingCart,
  updatingCartFinish
} from '../actions/loading'
import axios from 'axios'
import { Notify } from 'notiflix/build/notiflix-notify-aio'
import _ from 'lodash'

const baseURL =
  process.env.REACT_APP_PROD_MODE === 'test'
    ? process.env.REACT_APP_API_URL_TEST
    : process.env.REACT_APP_API_URL_LIVE

function* handleGetFavourites () {
  const token = JSON.parse(localStorage.getItem('kunochUser'))?.token

  const apiCall = async () => {
    try {
      return await axios.get(`${baseURL}/favourites/get`, {
        headers: {
          'x-auth-token': token
        }
      })
    } catch (error) {
      // throw error
    }
  }

  try {
    yield put(loading())

    const response = yield call(apiCall)

    if (response.status === 200) {
      yield put(getFavouritesSuccessful(response.data.favourites))
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notify.failure(error.response.data.message)
    }
  } finally {
    yield put(loadingFinish())
  }
}

function* handleGetCart () {
  const token = JSON.parse(localStorage.getItem('kunochUser'))?.token
  const apiCall = async () => {
    try {
      return await axios.get(`${baseURL}/cart/get`, {
        headers: {
          'x-auth-token': token
        }
      })
    } catch (error) {
      throw error
    }
  }

  try {
    yield put(loading())

    const response = yield call(apiCall)

    if (response.status === 200) {
      const data = {
        total_price: response.data.cummulativeTotalPrice,
        items: [...response.data.shoppingBag]
      }
      yield put(getCartSuccessful(data))
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notify.failure(error.response.data.message)
    }
  } finally {
    yield put(loadingFinish())
  }
}

function* handleRemoveFavourite ({ payload }) {
  const token = JSON.parse(localStorage.getItem('kunochUser'))?.token

  const apiCall = async () => {
    try {
      return await axios.post(
        `${
          process.env.REACT_APP_PROD_MODE === 'test'
            ? process.env.REACT_APP_API_URL_TEST
            : process.env.REACT_APP_API_URL_LIVE
        }/favourites/remove`,
        { productId: [payload] },
        {
          headers: {
            'x-auth-token': token
          }
        }
      )

      // if (res.data.status === 'success') {
      //   Notify.success(res.data.message)
      // }
    } catch (error) {
      Notify.failure('Server error, please try again')
    }
  }

  const apiGetFav = async () => {
    const token = JSON.parse(localStorage.getItem('kunochUser'))?.token
    try {
      return await axios.get(`${baseURL}/favourites/get`, {
        headers: {
          'x-auth-token': token
        }
      })
    } catch (error) {
      throw error
    }
  }

  try {
    const response = yield call(apiCall)

    if (response.status === 200 || response.status === 201) {
      Notify.success(response.data.message)
      const res = yield call(apiGetFav)
      yield put(getFavouritesSuccessful(res.data.favourites))
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notify.failure(error.response.data.message)
    }
  }
}

function* handleAddFavourite ({ payload }) {
  const token = JSON.parse(localStorage.getItem('kunochUser'))?.token

  const apiCall = async () => {
    try {
      return await axios.post(
        `${
          process.env.REACT_APP_PROD_MODE === 'test'
            ? process.env.REACT_APP_API_URL_TEST
            : process.env.REACT_APP_API_URL_LIVE
        }/favourites/create`,
        { productId: [payload] },
        {
          headers: {
            'x-auth-token': token
          }
        }
      )
    } catch (error) {
      throw error
    }
  }

  try {
    const response = yield call(apiCall)

    if (response.status === 200 || response.status === 201) {
      Notify.success(response.data.message)
      yield put(getFavourites())
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notify.failure(error.response.data.message)
    }
  }
}

function* handleAddItem ({ payload }) {
  const token = JSON.parse(localStorage.getItem('kunochUser'))?.token
  yield put(updatingCart())
  yield put(loading())

  if (!token) {
    let cart = []
    const contains = false

    if (localStorage.getItem('kunochCart')) {
      cart = [...JSON.parse(localStorage.getItem('kunochCart'))]

      if (
        cart.some(
          item =>
            item.variationName === payload[0].variationName &&
            item.productId === payload[0].productId
        )
      ) {
        const itemIndex = cart.findIndex(
          item =>
            item.variationName === payload[0].variationName &&
            item.productId === payload[0].productId
        )

        cart[itemIndex].noItems += parseInt(payload[0].noItems)
        localStorage.setItem('kunochCart', JSON.stringify(cart))

        yield put(
          getCartSuccessful({
            total_price: JSON.parse(localStorage.getItem('kunochCart')).reduce(
              (sum, item) =>
                sum + parseInt(item.productPrice) * parseInt(item.noItems),
              0
            ),
            items: JSON.parse(localStorage.getItem('kunochCart'))
          })
        )

        yield put(updatingCartFinish())
        Notify.success('Successfully added to cart')

        return
      } else if (
        cart.some(
          item =>
            item.variationName !== payload[0].variationName &&
            item.productId === payload[0].productId
        )
      ) {
        payload[0].noItems = parseInt(payload[0].noItems)

        cart.push(payload[0])
        localStorage.setItem('kunochCart', JSON.stringify(cart))

        yield put(
          getCartSuccessful({
            total_price: JSON.parse(localStorage.getItem('kunochCart')).reduce(
              (sum, item) =>
                sum + parseInt(item.productPrice) * parseInt(item.noItems),
              0
            ),
            items: JSON.parse(localStorage.getItem('kunochCart'))
          })
        )

        yield put(updatingCartFinish())
        Notify.success('Successfully added to cart')

        return
      } else if (
        cart.some(
          item =>
            item.variationName !== payload[0].variationName &&
            item.productId !== payload[0].productId
        )
      ) {
        payload[0].noItems = parseInt(payload[0].noItems)

        cart.push(payload[0])
        localStorage.setItem('kunochCart', JSON.stringify(cart))

        yield put(
          getCartSuccessful({
            total_price: JSON.parse(localStorage.getItem('kunochCart')).reduce(
              (sum, item) =>
                sum + parseInt(item.productPrice) * parseInt(item.noItems),
              0
            ),
            items: JSON.parse(localStorage.getItem('kunochCart'))
          })
        )

        yield put(updatingCartFinish())
        Notify.success('Successfully added to cart')

        return
      } else if (
        cart.some(
          item =>
            item.variationName === '' &&
            payload[0].variationName === '' &&
            item.productId !== payload[0].productId
        )
      ) {
        payload[0].noItems = parseInt(payload[0].noItems)

        cart.push(payload[0])
        localStorage.setItem('kunochCart', JSON.stringify(cart))

        yield put(
          getCartSuccessful({
            total_price: JSON.parse(localStorage.getItem('kunochCart')).reduce(
              (sum, item) =>
                sum + parseInt(item.productPrice) * parseInt(item.noItems),
              0
            ),
            items: JSON.parse(localStorage.getItem('kunochCart'))
          })
        )

        yield put(updatingCartFinish())
        Notify.success('Successfully added to cart')

        return
      }
    } else if (!localStorage.getItem('kunochCart')) {
      cart.push({
        productId: payload[0].productId,
        productPrice: payload[0].productPrice,
        noItems: parseInt(payload[0].noItems),
        productImage: payload[0].productImage,
        productName: payload[0].productName,
        productQuantity: payload[0].productQuantity,
        variationName: payload[0].variationName,
        slug: payload[0].slug,
        unitOfMeasure: payload[0]?.unitOfMeasure
      })

      localStorage.setItem('kunochCart', JSON.stringify(cart))
      yield put(
        getCartSuccessful({
          total_price: JSON.parse(localStorage.getItem('kunochCart')).reduce(
            (sum, item) =>
              sum + parseInt(item.productPrice) * parseInt(item.noItems),
            0
          ),
          items: JSON.parse(localStorage.getItem('kunochCart'))
        })
      )
      yield put(updatingCartFinish())
      Notify.success('Successfully added to cart')
      return
    }
  }

  if (token) {
    const apiCall = async () => {
      try {
        return await axios.post(
          `${
            process.env.REACT_APP_PROD_MODE === 'test'
              ? process.env.REACT_APP_API_URL_TEST
              : process.env.REACT_APP_API_URL_LIVE
          }/cart/add`,
          {
            payload
            // payload: [
            //   {
            //     productId: payload._id,
            //     noItems: parseInt(payload.noItems),
            //     productPrice: payload.price,
            //     categoryName: payload.category?.categoryName
            //   }
            // ]
          },

          {
            headers: {
              'x-auth-token': token
            }
          }
        )
      } catch (error) {
        throw error
      }
    }

    try {
      const response = yield call(apiCall)

      if (response.status === 201) {
        Notify.success(response.data.message || 'Successfully added to cart')
        yield put(getCart())
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        Notify.failure(error.response.data.message)
      }
    } finally {
      yield put(updatingCartFinish())
    }
  }
  yield put(loadingFinish())
}

function* handleRemoveItem ({ payload }) {
  const token = JSON.parse(localStorage.getItem('kunochUser'))?.token

  yield put(updatingCart())

  // implementing offline feature - remove item from cart
  if (!token) {
    const cart = JSON.parse(localStorage.getItem('kunochCart'))
    cart.splice(
      _.findIndex(
        cart,
        item => item?.product?._id === payload || item?.productId === payload
      ),
      1
    )

    if (cart.length < 1) {
      yield put(getCartSuccessful({}))
      localStorage.removeItem('kunochCart')
      yield put(updatingCartFinish())
      Notify.success('Successfully removed item from cart')
      return
    } else {
      yield put(
        getCartSuccessful({
          total_price: cart.reduce(
            (sum, item) => sum + item.productPrice * item.noItems,
            0
          ),
          items: cart
        })
      )
      localStorage.setItem('kunochCart', JSON.stringify(cart))
      yield put(updatingCartFinish())
      Notify.success('Successfully removed item from cart')
      return
    }
  }

  const apiCall = async () => {
    try {
      return await axios.delete(
        `${
          process.env.REACT_APP_PROD_MODE === 'test'
            ? process.env.REACT_APP_API_URL_TEST
            : process.env.REACT_APP_API_URL_LIVE
        }/cart/remove/${payload}`,
        {
          headers: {
            'x-auth-token': token
          }
        }
      )
    } catch (error) {
      throw error
    }
  }

  try {
    const response = yield call(apiCall)

    if (response.status === 200) {
      Notify.success(response.data.message)
      yield put(getCart())
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notify.failure(error.response.data.message)
    }
  } finally {
    yield put(updatingCartFinish())
  }
}

function* handleClearCart () {
  const token = JSON.parse(localStorage.getItem('kunochUser'))?.token
  yield put(updatingCart())

  const apiCall = async () => {
    try {
      return await axios.delete(
        `${
          process.env.REACT_APP_PROD_MODE === 'test'
            ? process.env.REACT_APP_API_URL_TEST
            : process.env.REACT_APP_API_URL_LIVE
        }/cart/empty`,
        {
          headers: {
            'x-auth-token': token
          }
        }
      )
    } catch (error) {
      throw error
    }
  }

  try {
    const response = yield call(apiCall)

    if (response.status === 200) {
      Notify.success(response.data.message)
      yield put(getCart())
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notify.failure(error.response.data.message)
    }
  } finally {
    yield put(updatingCartFinish())
  }
}

function* handleUpdateItem ({ payload }) {
  const token = JSON.parse(localStorage.getItem('kunochUser'))?.token
  yield put(updatingCart())

  if (!token) {
    let cart

    if (localStorage.getItem('kunochCart')) {
      cart = [...JSON.parse(localStorage.getItem('kunochCart'))]
      const index = _.findIndex(
        cart,
        item =>
          (item.productId === payload.product.productId &&
            item.variationName === payload.product.variationName) ||
          item.productId === payload.product._id
      )

      // console.log('this is saged', payload)

      cart[index] = {
        productId: payload.product._id || payload.product.productId,
        productName: payload.product.productName,
        productImage:
          payload.product.main_image || payload.product.productImage,
        slug: payload?.product?.slug,
        unitOfMeasure: payload?.product?.unitOfMeasure,
        // price: payload.product.productPrice * payload.product.noItems,
        productPrice: payload.product.price || payload.product.productPrice,
        noItems: payload.itemNo,
        productQuantity:
          payload.product.quantity || payload.product.productQuantity,
        variationName: payload.product.variationName
      }

      localStorage.setItem('kunochCart', JSON.stringify(cart))

      // console.log('this is sag', cart[index])
    }

    yield put(
      getCartSuccessful({
        total_price: JSON.parse(localStorage.getItem('kunochCart')).reduce(
          (sum, item) =>
            sum + parseInt(item.productPrice) * parseInt(item.noItems),

          // item.price,
          0
        ),
        items: JSON.parse(localStorage.getItem('kunochCart'))
      })
    )
    yield put(updatingCartFinish())
    Notify.success('Cart successfully updated')
    return
  }

  // console.log('this is payload', payload)

  const apiCall = async () => {
    try {
      return await axios.post(
        `${
          process.env.REACT_APP_PROD_MODE === 'test'
            ? process.env.REACT_APP_API_URL_TEST
            : process.env.REACT_APP_API_URL_LIVE
        }/cart/update_cart/${
          payload?.product?.productId?._id ?? payload?.product?._id
        }`,
        { itemNo: payload?.itemNo || payload?.product?.itemNo },
        {
          headers: {
            'x-auth-token': token
          }
        }
      )
    } catch (error) {
      throw error
    }
  }

  try {
    const response = yield call(apiCall)

    if (response.status === 201) {
      Notify.success(response.data.message)
      yield put(getCart())
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notify.failure(error.response.data.message)
    }
  } finally {
    yield put(updatingCartFinish())
  }
}

function* handleAddItemFromBasket ({ payload }) {
  const token = JSON.parse(localStorage.getItem('kunochCart'))?.token
  yield put(updatingCart())
  yield put(loading())

  if (token) {
    const apiCall = async () => {
      try {
        return await axios.post(
          `${
            process.env.REACT_APP_PROD_MODE === 'test'
              ? process.env.REACT_APP_API_URL_TEST
              : process.env.REACT_APP_API_URL_LIVE
          }/cart/addFromBasket`,
          {
            payload
            // payload: [
            //   {
            //     productId: payload._id,
            //     noItems: parseInt(payload.noItems),
            //     productPrice: payload.price,
            //     categoryName: payload.category?.categoryName
            //   }
            // ]
          },

          {
            headers: {
              'x-auth-token': token
            }
          }
        )
      } catch (error) {
        throw error
      }
    }

    try {
      const response = yield call(apiCall)

      if (response.status === 201) {
        Notify.success(response.data.message || 'Successfully added to cart')
        yield put(getCart())
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        Notify.failure(error.response.data.message)
      }
    } finally {
      yield put(updatingCartFinish())
    }
  }
  yield put(loadingFinish())
}

const authSaga = [
  takeLatest(USER.ADD_FAVOURITE, handleAddFavourite),
  takeLatest(USER.ADD_ITEM, handleAddItem),
  // takeLatest(USER.ADD_ITEM, handleAddItemFromBasket),
  takeLatest(USER.GET_FAVOURITES, handleGetFavourites),
  takeLatest(USER.GET_CART, handleGetCart),
  takeLatest(USER.REMOVE_ITEM, handleRemoveItem),
  takeLatest(USER.CLEAR_CART, handleClearCart),
  takeLatest(USER.UPDATE_ITEM, handleUpdateItem),
  takeLatest(USER.REMOVE_FAVOURITE, handleRemoveFavourite)
]

export default authSaga
