import Toolbar from '@mui/material/Toolbar'
import Stack from '@mui/material/Stack'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Menu from '@mui/material/Menu'
import MenuList from '@mui/material/MenuList'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import Grow from '@mui/material/Grow'
import MenuItem from '@mui/material/MenuItem'
import Badge from '@mui/material/Badge'
import Typography from '@mui/material/Typography'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { CgMenuBoxed } from 'react-icons/cg'
import { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useLocation, useHistory, Link } from 'react-router-dom'
import { getLoginReturnPage } from '../../../store/actions/user'
import { userLogout } from '../../../store/actions/auth'

import MobileDrawer from './MobileDrawer'
import logo from '../../../images/logo.svg'
import facebookIcon from '../../../images/facebookIcon.svg'
import instagramIcon from '../../../images/instagramIcon.svg'
import twitterIcon from '../../../images/twitterIcon.svg'
import phoneIcon from '../../../images/phoneIcon.svg'
import cartIcon from '../../../images/cartIcon.svg'
import favouriteIcon from '../../../images/favouriteIcon.svg'
import userIcon from '../../../images/userIcon.svg'
import userIconwhite from '../../../images/userIconWhite.svg'
import favouriteIconWhite from '../../../images/favouriteIconWhite.svg'
import cartIconWhite from '../../../images/cartIconWhite.svg'
import styles from './styles/navbar.module.scss'
import {
  HOME_ROUTE,
  EXPLORE_ROUTE,
  SIGN_UP_ROUTE,
  FAVOURITES_ROUTE,
  CONTACT_ROUTE,
  LOGIN_ROUTE,
  ORDER_HISTORY_ROUTE,
  SHOPPING_BASKET_ROUTE,
  PROFILE_ROUTE
} from '../../../utils/routes'
import { hideOnPath } from '../../../utils/hideOnPath'

const socialIcons = [{ icon: instagramIcon, link: 'https://www.instagram.com/nazefarmsng/' }]

const navbarIcon = [favouriteIcon, cartIcon, userIcon]

const whiteNavbarIcon = [favouriteIconWhite, cartIconWhite, userIconwhite]

export default function Navbar () {
  const [drawerMobileOpen, setDrawerMobileOpen] = useState(false)
  // const [openMenu, setOpenMenu] = useState(false)
  const [anchorElNav, setAnchorElNav] = useState(false)
  // const open = Boolean(openMenu)
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const { pathname } = useLocation()
  const { push } = useHistory()
  const [openMenu, setOpenMenu] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)
  const anchorRef = useRef(null)

  const handleToggle = () => {
    setOpenMenu((prevOpen) => !prevOpen)
  }
  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpenMenu(false)
  }

  function handleListKeyDown (event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpenMenu(false)
    } else if (event.key === 'Escape') {
      setOpenMenu(false)
    }
  }

  const toggleMobileDrawer = (event) => {
    setDrawerMobileOpen(!drawerMobileOpen)
  }

  // const handleOpenMenu = (event) => {
  //   setOpenMenu(event.currentTarget)
  // }

  // const handleCloseMenu = () => {
  //   setOpenMenu(false)
  // }

  // const handleOpenNavMenu = (event) => {
  //   setAnchorElNav(event.currentTarget)
  // }

  // const handleCloseNavMenu = () => {
  //   setAnchorElNav(false)
  // }

  return (
    <>
      <AppBar
        elevation={0}
        sx={{
          backgroundColor: 'primary.main',
          paddingTop: '10px',
          paddingBottom: ' 10px',
          display: pathname.split('/').includes('admin') ? 'none' : 'block'
        }}
      >
        <Container
          maxWidth='lg'
        >
          <Box
            sx={{
              justifyContent: 'space-between',
              display: { xs: 'flex', md: 'none' }
            }}
          >
            <Box
              onClick={() => push('/')}
            >
              <img src={logo} width='70%' height='100%' />
            </Box>
            <Box
              sx={{
                fontSize: '40px',
                cursor: 'pointer'
              }}
              onClick={toggleMobileDrawer}
            >
              <CgMenuBoxed />
            </Box>
          </Box>
          <Box
            sx={{
              justifyContent: 'space-between',
              display: { xs: 'none', md: 'flex' },
              paddingTop: '15px',
              paddingBottom: '15px'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <Box
                sx={{
                  cursor: 'pointer'
                }}
                onClick={() => push('/')}
              >
                <img src={phoneIcon} width='100%' height='100%' />
              </Box>
              <Box
                sx={{
                  alignSelf: 'center',
                  paddingLeft: '10px',
                  fontSize: '15px',
                  width: '150%'
                }}
              >
                <a
                  style={{ color: 'white' }}
                  href='tel:234 908 832 9373'
                >+234 908 832 9373
                </a>
              </Box>
            </Box>
            <Box
              sx={{
                cursor: 'pointer'
              }}
              onClick={() => push('/')}
            >
              <img src={logo} width='100%' height='100%' />
            </Box>
            {scrollPosition > 50 && <Stack
              direction='row'
              spacing={3}
                                    >
              <Box
                component={NavLink}
                to={FAVOURITES_ROUTE}
                sx={{
                  cursor: 'pointer'
                }}
              >
                <img
                  src={favouriteIconWhite} alt='' width='100%' height='100%'
                />
              </Box>
              <Badge
                badgeContent={user?.cart?.items?.length}
                color='badgeColor'
                sx={{
                  '&.MuiBadge-root': {
                    color: 'white',
                    fontWeight: 'bold'
                  }
                }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              >
                <Box
                  component={NavLink}
                  to={SHOPPING_BASKET_ROUTE}
                  sx={{
                    cursor: 'pointer'
                  }}
                >
                  <img
                    src={cartIconWhite} alt='' width='100%' height='100%'
                  />
                </Box>
              </Badge>
              <Box
                sx={{
                  cursor: 'pointer'
                }}
                ref={anchorRef}
                id='composition-button'
                aria-controls={openMenu ? 'composition-menu' : undefined}
                aria-expanded={openMenu ? 'true' : undefined}
                aria-haspopup='true'
                onClick={handleToggle}
              >
                <img
                  src={userIconwhite} alt='' width='100%' height='100%'
                />
              </Box>
              <Popper
                open={openMenu}
                anchorEl={anchorRef.current}
                role='menu'
                placement='bottom-start'
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom-start' ? 'left top' : 'left bottom'
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={openMenu}
                          id='composition-menu'
                          aria-labelledby='composition-button'
                          onKeyDown={handleListKeyDown}
                        >
                          {!user.isLoggedIn
                            ? <>
                              <MenuItem
                                component={NavLink}
                                onClick={handleClose}
                                to={SIGN_UP_ROUTE}
                              >
                                Sign Up
                              </MenuItem>
                              <MenuItem
                                component={NavLink}
                                onClick={handleClose}
                                to={LOGIN_ROUTE}
                              >
                                Login
                              </MenuItem>
                            </>
                            : <>
                              <MenuItem
                                component={NavLink}
                                to={PROFILE_ROUTE}
                                onClick={handleClose}
                              >Profile
                              </MenuItem>
                              <MenuItem
                                onClick={handleClose}
                                component={NavLink}
                                to={ORDER_HISTORY_ROUTE}
                              >Order History
                              </MenuItem>
                              <MenuItem
                                onClick={(e) => {
                                  push('/')
                                  dispatch(userLogout())
                                  handleClose()
                                }}
                              >Logout
                              </MenuItem>
                            </>}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Stack>}
            {scrollPosition < 50 &&
              <Stack direction='row' spacing={3}>
                {socialIcons.map(({ icon, link }, index) => (
                  <Box
                    component='a'
                    target='_blank'
                    href={link}
                    key={index}
                  >
                    <img
                      style={{ fill: 'black' }}
                      src={icon} width='100%' height='100%'
                    />
                  </Box>
                ))}
              </Stack>}
          </Box>
        </Container>
      </AppBar>
      <Toolbar sx={{
        paddingBottom: { md: '100px' }
      }}
      />
      <Container
        sx={{ display: pathname === SIGN_UP_ROUTE || pathname === LOGIN_ROUTE || pathname.split('/').includes('admin') ? 'none' : 'block' }}
        maxWidth='lg'
      >
        <Box
          sx={{
            justifyContent: 'space-between',
            display: { xs: 'none', md: 'flex' },
            paddingTop: { md: '20px' }
          }}
        >
          <Stack
            direction='row'
            spacing={3}
          >
            <Box>
              <NavLink
                to={HOME_ROUTE}
                className={pathname === HOME_ROUTE ? styles.activeStyle : styles.unActiveStyle}
              >

                Home
              </NavLink>
            </Box>
            <Box
              sx={{
                cursor: 'pointer'
              }}
              onClick={() => push(EXPLORE_ROUTE, { sortBy: 'all' })}
              className={pathname === EXPLORE_ROUTE ? styles.activeStyle : styles.unActiveStyle}
            >
              <Box>
                Explore
              </Box>
            </Box>
            <NavLink
              to={CONTACT_ROUTE}
              className={pathname === CONTACT_ROUTE ? styles.activeStyle : styles.unActiveStyle}
            >
              <Box>
                Contact Us
              </Box>
            </NavLink>
          </Stack>
          {scrollPosition < 50 && <Stack
            direction='row'
            spacing={3}
                                  >
            <Box
              component={NavLink}
              to={FAVOURITES_ROUTE}
              sx={{
                cursor: 'pointer'
              }}
            >
              <img
                src={favouriteIcon} alt='' width='100%' height='100%'
              />
            </Box>
            <Badge
              badgeContent={user?.cart?.items?.length}
              color='badgeColor'
              sx={{
                '&.MuiBadge-root': {
                  color: 'white',
                  fontWeight: 'bold'
                }
              }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              <Box
                component={NavLink}
                to={SHOPPING_BASKET_ROUTE}
                sx={{
                  cursor: 'pointer'
                }}
              >
                <img
                  src={cartIcon} alt='' width='100%' height='100%'
                />
              </Box>
            </Badge>
            <Box
              sx={{
                cursor: 'pointer'
              }}
              ref={anchorRef}
              id='composition-button'
              aria-controls={openMenu ? 'composition-menu' : undefined}
              aria-expanded={openMenu ? 'true' : undefined}
              aria-haspopup='true'
              onClick={handleToggle}
            >
              <img
                src={userIcon} alt='' width='100%' height='100%'
              />
            </Box>
            <Popper
              open={openMenu}
              anchorEl={anchorRef.current}
              role='menu'
              placement='bottom-start'
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom-start' ? 'left top' : 'left bottom'
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={openMenu}
                        id='composition-menu'
                        aria-labelledby='composition-button'
                        onKeyDown={handleListKeyDown}
                      >
                        {!user.isLoggedIn
                          ? <>
                            <MenuItem
                              component={NavLink}
                              onClick={handleClose}
                              to={SIGN_UP_ROUTE}
                            >
                              Sign Up
                            </MenuItem>
                            <MenuItem
                              component={NavLink}
                              onClick={handleClose}
                              to={LOGIN_ROUTE}
                            >
                              Login
                            </MenuItem>
                          </>
                          : <>
                            <MenuItem
                              component={NavLink}
                              to={PROFILE_ROUTE}
                              onClick={handleClose}
                            >Profile
                            </MenuItem>
                            <MenuItem
                              onClick={handleClose}
                              component={NavLink}
                              to={ORDER_HISTORY_ROUTE}
                            >Order History
                            </MenuItem>
                            <MenuItem
                              onClick={(e) => {
                                push('/')
                                dispatch(userLogout())
                                handleClose()
                              }}
                            >Logout
                            </MenuItem>
                          </>}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Stack>}
        </Box>
      </Container>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <MobileDrawer
          drawerMobileOpen={drawerMobileOpen}
          toggleMobileDrawer={toggleMobileDrawer}
        />
      </Box>
    </>
  )
}
