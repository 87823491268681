import { takeLatest, put, call } from 'redux-saga/effects'
import { AUTH } from '../actions/types'
import { adminLoginSuccess, userLoginSuccess } from '../actions/auth'
import { loading, loadingFinish } from '../actions/loading'
import axios from 'axios'
import { Notify } from 'notiflix/build/notiflix-notify-aio'
import { getCart, getFavourites } from '../actions/user'
import { ContactSupportOutlined } from '@mui/icons-material'

const baseURL = process.env.REACT_APP_PROD_MODE === 'test'
  ? process.env.REACT_APP_API_URL_TEST
  : process.env.REACT_APP_API_URL_LIVE

function * handleUserLogin ({ payload, toggleModal }) {
  const apiCall = async () => {
    try {
      return await axios.post(`${baseURL}/auth`, payload)
    } catch (error) {
      Notify.failure(error.response.data.message)
    }
  }

  try {
    yield put(loading())

    const response = yield call(apiCall)

    if (response.status === 200) {
      const data = {
        ...response.data.decodeDetails,
        token: response.data.token
      }
      yield put(userLoginSuccess(data))
      Notify.success(response.data.message)

      if (localStorage.getItem('kunochCart')) {
        const cart = JSON.parse(localStorage.getItem('kunochCart')).map(item =>
        // ({
        //   noItems: item.noItems,
        //   productId: item.product._id,
        //   productPrice: item.product.price,
        //   categoryName: item.categoryName
        // })
          ({
            noItems: item.noItems,
            productId: item.productId,
            productPrice: item.productPrice,
            variationName: item.variationName,
            color: item.color,
            size: item.size,
            tags: item.tags,
            slug: item.slug,
            unitOfMeasure: item.unitOfMeasure
          })
        )

        const apiCall = async () => {
          try {
            return await axios.post(
              `${process.env.REACT_APP_PROD_MODE === 'test'
            ? process.env.REACT_APP_API_URL_TEST
            : process.env.REACT_APP_API_URL_LIVE}/cart/addFromBasket`,
              { payload: cart },
              {
                headers: {
                  'x-auth-token': response.data.token
                }
              }
            )
          } catch (error) {
            throw error
          }
        }

        try {
          const response = yield call(apiCall)

          if (response.status === 201) {
            Notify.success(response.data.message)
            localStorage.removeItem('kunochCart')
          }
        } catch (error) {
          if (error?.response?.data?.message) {
            Notify.failure(error.response.data.message)
          }
        }
      }

      yield put(getCart())
      yield put(getFavourites())
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notify.failure(error.response.data.message)
    } else {
      // Notify.failure('An error occurred! Please check your internet connection.')
    }
  } finally {
    yield put(loadingFinish())
  }
}

function * handleAdminLogin ({ payload, history }) {
  const apiCall = async () => {
    try {
      return await axios.post(`${baseURL}/adminUser/login`, payload)
    } catch (error) {
      throw error
    }
  }

  try {
    yield put(loading())

    const response = yield call(apiCall)

    if (response.status === 200) {
      const data = {
        ...response.data.decodeDetails,
        token: response.data.token
      }
      yield put(adminLoginSuccess(data))
      history.push('/admin')
      Notify.success(response.data.message)
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      Notify.failure(error.response.data.message)
    } else {
      Notify.failure('An error occurred! Please check your internet connection.')
    }
  } finally {
    yield put(loadingFinish())
  }
}

function * handleUserLogout () {}

const authSaga = [
  takeLatest(AUTH.ADMIN_LOGIN, handleAdminLogin),
  takeLatest(AUTH.USER_LOGIN, handleUserLogin),
  takeLatest(AUTH.USER_LOGOUT, handleUserLogout)
]

export default authSaga
