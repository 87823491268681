
import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  palette: {
    primary: {
      main: '#1AA3BA'
    },
    badgeColor: {
      main: ' #F3692E'
    }
  }
})
