import { combineReducers } from "redux";
import admin from "./adminReducer";
import user from "./userReducer";
import loading from "./loadingReducer";
import modal from "./modalReducer";

const rootReducer = combineReducers({
  admin,
  loading,
  user,
  modal,
});

export default rootReducer;
