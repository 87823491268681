import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

import loadingIcon from '../../images/loadingIcon.gif'

export default function LoadingIcon () {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        paddingTop: ' 300px'
      }}
    >
      <Box>
        <img
          src={loadingIcon}
          width='100%'
          height='100%'
        />
      </Box>
    </Box>
  )
}
