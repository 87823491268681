import { USER } from '../actions/types'

export const getFavourites = () => ({
  type: USER.GET_FAVOURITES
})

export const getFavouritesSuccessful = data => ({
  type: USER.GET_FAVOURITES_SUCCESS,
  payload: data
})

export const addToFavourite = data => ({
  type: USER.ADD_FAVOURITE,
  payload: data
})

export const removeFavourite = data => ({
  type: USER.REMOVE_FAVOURITE,
  payload: data
})

export const addItem = data => ({
  type: USER.ADD_ITEM,
  payload: data
})

export const removeItem = data => ({
  type: USER.REMOVE_ITEM,
  payload: data
})
export const getCart = () => ({
  type: USER.GET_CART
})

export const getCartSuccessful = data => ({
  type: USER.GET_CART_SUCCESS,
  payload: data
})

export const clearCart = () => ({
  type: USER.CLEAR_CART
})

export const clearCartSuccess = data => ({
  type: USER.CLEAR_CART_SUCCESS,
  payload: data
})

export const updateItem = data => ({
  type: USER.UPDATE_ITEM,
  payload: data
})

export const getLoginReturnPage = (data) => ({
  type: USER.GET_LOGIN_RETURN_PAGE,
  payload: data
})
