import { LOADING } from "./types";

export const loading = () => ({
  type: LOADING.LOADING,
});

export const loadingFinish = () => ({
  type: LOADING.FINISH,
});

export const updatingCart = () => ({
  type: LOADING.UPDATING_CART,
});

export const updatingCartFinish = () => ({
  type: LOADING.UPDATING_CART_FINISH,
});
