import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Badge from '@mui/material/Badge'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import logo from '../../../images/logo.svg'
import facebookIcon from '../../../images/facebookIcon.svg'
import instagramIcon from '../../../images/instagramIcon.svg'
import twitterIcon from '../../../images/twitterIcon.svg'
import { getLoginReturnPage } from '../../../store/actions/user'
import {
  HOME_ROUTE,
  EXPLORE_ROUTE,
  SIGN_UP_ROUTE,
  FAVOURITES_ROUTE,
  CONTACT_ROUTE,
  LOGIN_ROUTE,
  ORDER_HISTORY_ROUTE,
  PROFILE_ROUTE,
  SHOPPING_BASKET_ROUTE
} from '../../../utils/routes'
import { userLogout } from '../../../store/actions/auth'

const socialIcons = [{ icon: instagramIcon, link: 'https://www.instagram.com/nazefarmsng/' }]
const notLoggedIn = [{ text: 'Home', link: HOME_ROUTE }, { text: 'Explore', link: EXPLORE_ROUTE }, { text: 'Contact Us', link: CONTACT_ROUTE }, { text: 'Favourites', link: FAVOURITES_ROUTE }, { text: 'Cart', link: '#' }, { text: 'Login', link: LOGIN_ROUTE }, { text: 'Sign Up', link: SIGN_UP_ROUTE }]
const loggedIn = ['Home', 'Explore', 'Contact Us', 'Profile', 'Order History', 'Favourites', 'Cart', 'Logout']

export default function MobileDrawer({
  drawerMobileOpen,
  toggleMobileDrawer
}) {
  const dispatch = useDispatch()
  const { push } = useHistory()
  const { pathname } = useLocation()
  const user = useSelector(state => state.user)

  return (
    <SwipeableDrawer
      sx={{
        '& .MuiDrawer-paper': {
          backgroundColor: 'primary.main'
        }
      }}
      anchor='right'
      open={drawerMobileOpen}
      onClose={toggleMobileDrawer}
      onOpen={toggleMobileDrawer}
    >
      <Box
        sx={{ width: 250, padding: '30px' }}
        role='presentation'
        onClick={toggleMobileDrawer}
        onKeyDown={toggleMobileDrawer}
      >
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
        >
          <Box
            onClick={() => push('/')}
          >
            <img
              src={logo}
              width='70%'
              height='100%'
              alt='white logo'
            />
          </Box>
        </Box>
        <Box sx={{ paddingTop: '50px' }} />
        <List>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => push(HOME_ROUTE)}
            >
              <ListItemText
                primary={
                  <Box
                    sx={{
                      fontSize: '20px',
                      fontWeight: 600,
                      color: 'white'
                    }}
                  >
                    Home
                  </Box>
                }
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                push(EXPLORE_ROUTE, { sortBy: 'all' })
                toggleMobileDrawer()
              }}
            >
              <ListItemText
                primary={
                  <Box
                    sx={{
                      fontSize: '20px',
                      fontWeight: 600,
                      color: 'white'
                    }}
                  >
                    Explore
                  </Box>
                }
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => push(CONTACT_ROUTE)}
            >
              <ListItemText
                primary={
                  <Box
                    sx={{
                      fontSize: '20px',
                      fontWeight: 600,
                      color: 'white'
                    }}
                  >
                    Contact Us
                  </Box>
                }
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => push(FAVOURITES_ROUTE)}
            >
              <ListItemText
                primary={
                  <Box
                    sx={{
                      fontSize: '20px',
                      fontWeight: 600,
                      color: 'white'
                    }}
                  >
                    Favourites
                  </Box>
                }
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => push(SHOPPING_BASKET_ROUTE)}
            >
              <ListItemText
                primary={
                  <Badge
                    badgeContent={user?.cart?.items?.length}
                    color='badgeColor'
                    sx={{
                      '&.MuiBadge-root': {
                        color: 'white',
                        fontWeight: 'bold'
                      }
                    }}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: '20px',
                        fontWeight: 600,
                        color: 'white'
                      }}
                    >
                      Cart
                    </Box>
                  </Badge>
                }
              />
            </ListItemButton>
          </ListItem>
          {!user.isLoggedIn
            ? <>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={(e) => {
                    dispatch(getLoginReturnPage(pathname))
                    push(LOGIN_ROUTE)
                  }}
                >
                  <ListItemText
                    primary={
                      <Box
                        sx={{
                          fontSize: '20px',
                          fontWeight: 600,
                          color: 'white'
                        }}
                      >
                        Login
                      </Box>
                    }
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => push(SIGN_UP_ROUTE)}
                >
                  <ListItemText
                    primary={
                      <Box
                        sx={{
                          fontSize: '20px',
                          fontWeight: 600,
                          color: 'white'
                        }}
                      >
                        Sign Up
                      </Box>
                    }
                  />
                </ListItemButton>
              </ListItem>
            </>
            : <>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={(e) => {
                    push(PROFILE_ROUTE)
                  }}
                >
                  <ListItemText
                    primary={
                      <Box
                        sx={{
                          fontSize: '20px',
                          fontWeight: 600,
                          color: 'white'
                        }}
                      >
                        Profile
                      </Box>
                    }
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => push(ORDER_HISTORY_ROUTE)}
                >
                  <ListItemText
                    primary={
                      <Box
                        sx={{
                          fontSize: '20px',
                          fontWeight: 600,
                          color: 'white'
                        }}
                      >
                        Order History
                      </Box>
                    }
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={(e) => {
                    dispatch(userLogout())
                    push('/')
                  }}
                >
                  <ListItemText
                    primary={
                      <Box
                        sx={{
                          fontSize: '20px',
                          fontWeight: 600,
                          color: 'white'
                        }}
                      >
                        Log Out
                      </Box>
                    }
                  />
                </ListItemButton>
              </ListItem>
            </>}
        </List>
        <Box sx={{ paddingTop: '50px' }} />
        <Box sx={{
          paddingTop: '30px',
          fontSize: '15px',
          color: 'white'
        }}
        >
          <Box>Connect with us on</Box>
          <Stack
            spacing={2}
            direction='row'
            sx={{ paddingTop: '20px' }}
          >
            {socialIcons.map(({ icon, link }, index) => (
              <Box
                component='a'
                target='_blank'
                href={link}
                key={index}
              >
                <img
                  src={icon}
                  width='30px'
                  height='30px'
                  alt={icon}
                />
              </Box>
            ))}
          </Stack>
        </Box>
      </Box>
    </SwipeableDrawer>
  )
}
