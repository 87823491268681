import './styles/App.scss'
import CssBaseline from '@mui/material/CssBaseline'
import { useEffect, Suspense, lazy } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { Switch, Route, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from 'notiflix/build/notiflix-loading-aio'
import { gapi } from 'gapi-script'

import logoIcon from './images/logoIcon.svg'
import LoadingIcon from './components/user/Loading'
import ScrollToTop from './utils/ScrollToTop'

// import Home from './pages/Home'
import Navbar from './components/user/Navbar'
import Footer from './components/user/Footer'
// import Explore from './pages/Explore'
// import SignUp from './components/user/SignUp'
// import Login from './components/user/Login'
// import ShoppingBasket from './components/user/ShoppingBasket'
// import ProductDetails from './components/user/ProductDetails'
// import Checkout from './components/user/Checkout'
// import Favourites from './components/user/Favourites'
// import OrderHistory from './components/user/OrderHistory'
// import Profile from './components/user/Profile'
// import Contact from './components/user/Contact'
import { theme } from './utils/theme'
import {
  HOME_ROUTE,
  EXPLORE_ROUTE,
  PRODUCT_DETAILS_ROUTE,
  SIGN_UP_ROUTE,
  LOGIN_ROUTE,
  SHOPPING_BASKET_ROUTE,
  CHECKOUT_ROUTE,
  FAVOURITES_ROUTE,
  CONTACT_ROUTE,
  ORDER_HISTORY_ROUTE,
  PROFILE_ROUTE,
  PRIVACY_ROUTE,
  TERMS_CONDITIONS_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  RESET_PASSWORD_ROUTE
} from './utils/routes'
import { userLoginSuccess, userLogout } from './store/actions/auth'
import { getCart, getCartSuccessful, getFavourites } from './store/actions/user'

// lazy loading
const Home = lazy(() => import('./pages/Home'))
const Explore = lazy(() => import('./pages/Explore'))
const SignUp = lazy(() => import('./components/user/SignUp'))
const Login = lazy(() => import('./components/user/Login'))
const ShoppingBasket = lazy(() => import('./components/user/ShoppingBasket'))
const ProductDetails = lazy(() => import('./components/user/ProductDetails'))
const Checkout = lazy(() => import('./components/user/Checkout'))
const Favourites = lazy(() => import('./components/user/Favourites'))
const OrderHistory = lazy(() => import('./components/user/OrderHistory'))
const Profile = lazy(() => import('./components/user/Profile'))
const Contact = lazy(() => import('./components/user/Contact'))
const Admin = lazy(() => import('./components/admin'))
const Privacy = lazy(() => import('./components/user/Privacy'))
const TermConditions = lazy(() => import('./components/user/TermsConditions'))
const ForgotPassword = lazy(() => import('./components/user/ForgotPassword'))
const ResetPassword = lazy(() => import('./components/user/ResetPassword'))

function App () {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)
  const { push } = useHistory()

  useEffect(() => {
    if (localStorage.getItem('kunochUser')) {
      const data = JSON.parse(localStorage.getItem('kunochUser'))
      dispatch(userLoginSuccess(data))
      dispatch(getCart())
      dispatch(getFavourites())
    }

    if (localStorage.getItem('kunochCart')) {
      dispatch(
        getCartSuccessful({
          total_price: JSON.parse(localStorage.getItem('kunochCart')).reduce(
            (sum, item) => sum + item.productPrice * item.noItems,
            0
          ),
          items: JSON.parse(localStorage.getItem('kunochCart'))
        })
      )
    }
  }, [])

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId:
          '1040967114565-i57d18lu5aqf4h83esfqccntvlf6ab9b.apps.googleusercontent.com',
        scope: ''
      })
    }
    gapi.load('client:auth2', initClient)
  }, [])

  return (
    <div>
      <Suspense fallback={<LoadingIcon />}>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <Navbar />
          <ScrollToTop>
            <Switch>
              <Route path={RESET_PASSWORD_ROUTE} component={ResetPassword} />
              <Route path={FORGOT_PASSWORD_ROUTE} component={ForgotPassword} />
              <Route path={TERMS_CONDITIONS_ROUTE} component={TermConditions} />
              <Route path={PRIVACY_ROUTE} component={Privacy} />
              <Route path={PROFILE_ROUTE} component={Profile} />
              <Route path={ORDER_HISTORY_ROUTE} component={OrderHistory} />
              <Route path={CONTACT_ROUTE} component={Contact} />
              <Route path={FAVOURITES_ROUTE} component={Favourites} />
              <Route path={CHECKOUT_ROUTE} component={Checkout} />
              <Route path={SHOPPING_BASKET_ROUTE} component={ShoppingBasket} />
              <Route path={LOGIN_ROUTE} component={Login} />
              <Route path={SIGN_UP_ROUTE} component={SignUp} />
              <Route path={PRODUCT_DETAILS_ROUTE} component={ProductDetails} />
              <Route path={EXPLORE_ROUTE} component={Explore} />
              <Route path='/admin' component={Admin} />
              <Route path={HOME_ROUTE} component={Home} />
            </Switch>
          </ScrollToTop>
          <Footer />
        </ThemeProvider>
      </Suspense>
    </div>
  )
}

export default App
