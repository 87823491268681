import { AUTH, USER } from '../actions/types'

const userReducer = (state = {}, action) => {
  switch (action.type) {
    case AUTH.USER_LOGIN_SUCCESS:
      const user = action.payload
      localStorage.setItem('kunochUser', JSON.stringify(user))
      return { ...state, ...user, isLoggedIn: true }

    case AUTH.USER_LOGOUT:
      localStorage.removeItem('kunochUser')
    localStorage.removeItem('kunochCart')
      return {}

    case USER.GET_CART_SUCCESS:
      return { ...state, cart: { ...action.payload } }

    case USER.GET_FAVOURITES_SUCCESS:
      return { ...state, favourites: action.payload }

    case USER.GET_LOGIN_RETURN_PAGE:
      return { ...state, loginReturnPage: action.payload }

    default:
      return state
  }
}

export default userReducer
