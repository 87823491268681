export const HOME_ROUTE = '/'
export const EXPLORE_ROUTE = '/explore'
export const PRODUCT_DETAILS_ROUTE = '/product-details/:slug'
export const SIGN_UP_ROUTE = '/sign-up'
export const LOGIN_ROUTE = '/login'
export const SHOPPING_BASKET_ROUTE = '/shopping-basket'
export const CHECKOUT_ROUTE = '/checkout'
export const FAVOURITES_ROUTE = '/favourites'
export const CONTACT_ROUTE = '/contact'
export const ORDER_HISTORY_ROUTE = '/order-history'
export const PROFILE_ROUTE = '/profile'
export const PRIVACY_ROUTE = '/privacy'
export const TERMS_CONDITIONS_ROUTE = '/term-and-conditions'
export const FORGOT_PASSWORD_ROUTE = '/forgot-password'
export const RESET_PASSWORD_ROUTE = '/reset-password'
