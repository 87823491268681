const AUTH = {
  ADMIN_LOGIN: 'ADMIN_LOGIN',
  ADMIN_LOGOUT: 'ADMIN_LOGOUT',
  ADMIN_LOGIN_SUCCESS: 'ADMIN_LOGIN_SUCCESS',
  USER_LOGIN: 'USER_LOGIN',
  USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  USER_LOGOUT: 'USER_LOGOUT'
}

const LOADING = {
  LOADING: 'LOADING',
  FINISH: 'FINISH',
  UPDATING_CART: 'UPDATING_CART',
  UPDATING_CART_FINISH: 'UPDATING_CART_FINISH'
}

const USER = {
  GET_FAVOURITES: 'GET_FAVOURITES',
  GET_FAVOURITES_SUCCESS: 'GET_FAVOURITES_SUCCESS',
  GET_CART: 'GET_CART',
  ADD_ITEM: 'ADD_ITEM',
  REMOVE_ITEM: 'REMOVE_ITEM',
  ADD_FAVOURITE: 'ADD_FAVOURITE',
  GET_CART_SUCCESS: 'GET_CART_SUCCESS',
  CLEAR_CART: 'CLEAR_CART',
  CLEAR_CART_SUCCESS: 'CLEAR_CART_SUCCESS',
  UPDATE_ITEM: 'UPDATE_ITEM',
  REMOVE_FAVOURITE: 'REMOVE_FAVOURITE',
  GET_LOGIN_RETURN_PAGE: 'GET_LOGIN_RETURN_PAGE'
}

const MODAL = {
  LOGIN: 'LOGIN'
}

export { AUTH, LOADING, USER, MODAL }
