import { LOADING } from '../actions/types';

const loadingReducer = (state = {}, action) => {
  switch (action.type) {
    case LOADING.LOADING:
      return { loading: true }

    case LOADING.FINISH:
      return { loading: false }

    case LOADING.UPDATING_CART:
      return { ...state, cart: true }

    case LOADING.UPDATING_CART_FINISH:
      return { ...state, cart: false }

    default:
      return state
  }
}

export default loadingReducer
